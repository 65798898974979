import React, {useEffect, useRef, useState} from "react";
import {InputRef, Table} from "antd";
import {tableReportsColumns} from "./TableReportsColumns";
import {closeReports_url, tableReports_url} from "../../../config/api";
import {fetchWithToken} from "../../../config/helper";
import {notification} from "../../../common/Notification";
import getColumnSearchProps from "../../../common/ColumnSearch";

const TableReportsTable = () => {
    const [data, setData] = useState<Record<string, any>[]>([])
    const [loading, setLoading] = useState<boolean>(false);
    const [total, setTotal] = useState<number>(0)
    const [params, setParams] = useState<Record<string, number>>({
        page: 1,
        pageSize: 10
    })

    useEffect(() => {
        setLoading(true);
        let query = [];
        for (const key of Object.keys(params)) {
            if (key === "pageSize") {
                query.push(`results=${params.pageSize}`)
            } else {
                query.push(`${key}=${params[key]}`);
            }
        }
        const url = `${tableReports_url}?${query.join("&")}`;
        fetchWithToken(url, "get").then((res) => {
            if (res.status === 200) {
                res.json().then((data) => {
                    setData(data.results)
                    setTotal(data.total)
                }).finally(() => {
                    setLoading(false);
                })
            }
        })
    }, [params]);
    const searchInput = useRef<InputRef>(null);
    const closeReport = (id: string) => {
        const reqBody = JSON.stringify({
            reportId: id,
        });
        setLoading(true);
        fetchWithToken(closeReports_url, "post", {}, reqBody).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((data) => {
                    notification({
                        type: "success",
                        title: "Closed Successfully",
                        description: data.message
                    })
                    setParams({
                        ...params,
                        page: 1
                    })
                    setLoading(false);
                })
            } else {
                resp.json().then((data) => {
                    notification({
                        type: "warning",
                        title: "Something went Wrong",
                        description: data.message
                    })
                })
                setLoading(false);
            }
        }).catch((e) => {
            setLoading(false);
            notification({
                type: "error",
                title: "Something went Wrong",
                description: e.message
            })
        })
    };

    const handleTableChange = (pagination: any, filters: any) => {
        const temp: any = {
            page: pagination.current,
            pageSize: pagination.pageSize
        };
        for (const key of Object.keys(filters)) {
            if (filters[key]) {
                temp[key] = filters[key]
            }
        }
        setParams(temp);
    }
    return (
        <Table
            loading={loading}
            pagination={{
                current: params.page,
                pageSize: params.pageSize,
                total: total,
                showTotal: (total) => <span className={"text-2xl font-600 capitalize"}>Total: {total}</span>,
                showSizeChanger: true
            }}
            dataSource={data}
            columns={tableReportsColumns({
                actions: {
                    closeReport: closeReport,
                },
                searchProp: (dataIndex:any)=>getColumnSearchProps(searchInput, dataIndex)
            })}
            onChange={handleTableChange}
            scroll={{y: "55vh"}}
        />
    );
};

export default TableReportsTable;
