import React from 'react';
import {Button, Dropdown, Input, Modal, Typography} from "antd";
import {fetchWithToken} from "../../../config/helper";
import {sendNotifications_Url} from "../../../config/api";
import {notification} from "../../../common/Notification";

const SendBulkNotification = (props: { ids: React.Key[], filters: any }) => {
    const [open, setOpen] = React.useState(false)
    const [title, setTitle] = React.useState('')
    const [body, setBody] = React.useState('')
    const [type, setType] = React.useState('')
    const [loading, setLoading] = React.useState(false)

    const sendNotification = () => {
        setLoading(true)
        fetchWithToken(sendNotifications_Url, "post", {}, JSON.stringify({
            ids: props.ids,
            option: type === '1' ? 'all' : type === '2' ? 'filtered' : 'selected',
            title,
            body,
            filters: props.filters
        })).then((resp) => {
            if (resp.status === 200) {
                notification({
                    description: `Notification successfully sent ${menuProps.items[parseInt(type) - 1].label}`,
                    type: "success",
                    title: <p className={"capitalize"}>Notification sent</p>
                });
                setOpen(false)
                setBody('')
                setTitle('')
            } else {
                resp.json().then((data) => {
                    notification({
                        type: "warning",
                        title: "Something went Wrong",
                        description: data.message
                    })
                })
            }
        }).catch((e) => {
            notification({
                type: "error",
                title: "Something went Wrong",
                description: e.message
            })
        }).finally(() => {
            setLoading(false)
        })
    }
    const menuProps = {
        items: [
            {key: '1', label: 'To All Users In DB'},
            {key: '2', label: 'To All Filtered Users'},
            {key: '3', label: 'To Selected Users On This Page'}
        ],
        onClick: (e: any) => {
            setOpen(true)
            setType(e.key)
        }
    }
    return (
        <>
            <Dropdown menu={menuProps} placement="bottomRight">
                <Button type="primary">Send Notification</Button>
            </Dropdown>
            <Modal
                title={<>Send Notification<p className={'text-xl'}>{type ? menuProps.items[parseInt(type) - 1].label : ''}</p></>}
                open={open}
                onCancel={() => {
                    setOpen(false)
                    setBody('')
                    setTitle('')
                }}
                onOk={() => sendNotification()}
                okButtonProps={{loading: loading, disabled: !title || !body}}
                cancelButtonProps={{disabled: loading}}
            >
                <div className={'m-10'}>
                    <Typography.Text strong>Notification Title</Typography.Text>
                    <Input
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder={'Title'}
                    />
                </div>
                <div className={'m-10'}>
                    <Typography.Text strong>Notification Body</Typography.Text>
                    <Input.TextArea
                        value={body}
                        onChange={(e) => setBody(e.target.value)}
                        placeholder={'Body'}
                    />
                </div>
            </Modal>
        </>
    );
};

export default SendBulkNotification;